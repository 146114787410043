import React from 'react';
import classNames from 'classnames';
import { FormEvent } from 'react';
import { Container } from 'typedi';
import { Subscription } from 'rxjs/Subscription';
import { push } from 'connected-react-router';

import { Culture } from 'app/i18n/culture.actions';
import { Translation } from 'shared/components/translation/translation.component';
import { CultureSelectForm as CultureSelectFormModel } from 'shared/components/culture-select/culture-select.form';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';
import { Button } from 'shared/components/elements/elements.components';
import { cancelSubscription } from 'app/util/util';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { ComponentConnectorFactory } from 'store/component-connector';

import './culture-select.component.scss';

interface OwnProps {
  culture?: Culture;
  submitLabel?: ResourceKey;

  onSubmit(culture: Culture): void;

  onCancel?: () => any;
}

interface State {
  countries: GlobalizationCountry[];
}
interface ConnectDispatchProps {
  redirectToLogin: () => void;
}

type Props = OwnProps & ConnectDispatchProps;

export class CultureSelectFormComponent extends React.Component<Props> {
  state: State = {
    countries: []
  };
  static defaultProps = {
    submitLabel: 'SAVE' as ResourceKey
  };

  private form = new CultureSelectFormModel();
  private repo = Container.get(ReferenceDataRepository);
  formChangeSubscription: Subscription;

  componentDidMount() {
    this.formChangeSubscription = this.form.changes.subscribe(() => this.forceUpdate());
    if (this.props.culture) {
      this.form.setCulture(this.props.culture);
    }
    this.getCountries();
    if (this.props.culture.country.locales.length > 1) {
      this.form.get('locale').enable();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
    if (nextProps.culture && nextProps.culture !== this.props.culture) {
      this.form.setCulture(nextProps.culture);
    }
  }

  componentWillUnmount(): void {
    cancelSubscription(this.formChangeSubscription);
  }

  getCountries = () => {
    this.repo.getGlobalizationLocales()
      .subscribe((countries) => this.setState({countries}));
  };

  onCountryChange = value => {
    const countryName = value.currentTarget.value;
    const selectedCountry = this.state.countries.find(country => country.name === countryName);

    this.form.get('country').setValue(selectedCountry);
    this.form.get('locale').setValue(selectedCountry.locales[0]);

    if (selectedCountry.code && selectedCountry.locales.length === 1) {
      this.form.get('locale').disable();
    } else {
      this.form.get('locale').enable();
    }
  };

  onLanguageChange = event => {
    const localeDisplayName = event.currentTarget.value;
    const selectedLocale = this.form.value.country.locales.find(locale => locale.displayName === localeDisplayName);
    this.form.get('locale').setValue(selectedLocale);
  };

  onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.form.touch();

    if (this.form.valid) {

      this.props.onSubmit(this.form.toCulture());
    }

  };

  onCancel = () => {
    this.form.get('country').setValue(this.props.culture.country);
    this.form.setCulture(this.props.culture);
    if (this.props.onCancel) {
      this.props.onCancel();
    } else {
      this.props.redirectToLogin();
    }
  };

  render() {
    const {submitLabel} = this.props;
    const countryField: NavCarrierFormControl<GlobalizationCountry> = this.form.get('country');
    const localeField: NavCarrierFormControl<GlobalizationCultureLanguage> = this.form.get('locale');
    const {countries} = this.state;

    return (
      <form className="culture-select-form space-outer-top-md" onSubmit={this.onSubmit}>
        <div className={classNames('form-group', 'js-field-country', {'has-error': countryField.hasErrors()})}>
          <label htmlFor="country" className="required">
            <Translation resource="COUNTRY"/>
          </label>
          <select
            value={countryField.value.name}
            onChange={this.onCountryChange}
            className="select-country form-control"
          >
            {countries && countries.map((country) =>
              <option value={country.name} key={country.code}>{country.name}</option>
            )}
          </select>
        </div>
        <div
          className={classNames('form-group', 'js-field-locale', {'has-error': localeField.hasErrors() && !localeField.disabled})}>
          <label htmlFor="locale" className="required">
            <Translation resource="LANGUAGE"/>
          </label>
          <select
            id="select-country-language"
            className="select-country-language form-control"
            name="locale"
            onChange={this.onLanguageChange}
            value={localeField.value.displayName}
            disabled={localeField.disabled}
          >
            {countryField.value && countryField.value.locales && countryField.value.locales.map(locale =>
              <option value={locale.displayName} key={locale.locale}>{locale.displayName}</option>
            )}
          </select>
        </div>
        <Button btnPrimary btnBlock type="submit" className="space-outer-top-lg" resource={submitLabel}/>
        <Button btnLink btnBlock className="space-outer-top-md" resource="CANCEL" onClick={this.onCancel}/>
      </form>
    );
  }
}
export const CultureSelectForm = ComponentConnectorFactory<{}, ConnectDispatchProps>()
  .mapDispatchToProps({
    redirectToLogin: () => push('/login'),
  })
  .connect(CultureSelectFormComponent);
