import { useState, useEffect } from 'react';
import Container from 'typedi';

const marketoId = Container.get('appConstants.marketoId');
const marketoBaseUrl = Container.get('appConstants.marketoBaseUrl');

const appendScript = (setScriptLoaded) => {
  if (window['MktoForms2']) {
    return setScriptLoaded(true);
  }
  const script = document.createElement('script');
  script.src = `${marketoBaseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window['MktoForms2'] ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
};

export const useMarketo = ({ formId }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (scriptLoaded && formData) {
      window['MktoForms2'].loadForm(marketoBaseUrl, marketoId, formId, (form) => {
        form.addHiddenFields(formData);
        form.submit();
        form.onSuccess(() => {
          form.getFormElem().hide();
          // Return false to prevent the submission handler from taking the lead to the follow up url
          return false;
        });
      });
    }
    appendScript(setScriptLoaded);
  }, [scriptLoaded, formId, formData]);

  return { setFormData };
};

export const useMarketoInitialization = () => {
  const [isintialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isintialized) {
      (window as any)['Munchkin'].init(marketoId);
    }
    initiMarketo(setIsInitialized);
  }, [isintialized]);
};

const initiMarketo = (setIsInitialized) => {
  if ((window as any)['Munchkin']) {
    return setIsInitialized(true);
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = '//munchkin.marketo.net/munchkin.js';
  script.onload = () => (window as any)['Munchkin'] ? setIsInitialized(true) : null;
  document.getElementsByTagName('head')[0].appendChild(script);
};
